<template>
    <Introduction
        width="l"
        :title="$t('login.loggedout.title')"
        :actions="[
            {
                title: $t('app.home'),
                class: 'bg-accent',
                to: '/',
            },
        ]"
    />
</template>

<script>
import Introduction from '@/components/Introduction.vue';

export default {
    components: {
        Introduction,
    },
};
</script>
